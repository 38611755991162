<template>
  <div style="width: 100%; height: 100%;">
    <div id="chart" style="width: 100%; height: 100%;"></div>
  </div>
</template>

<script>
  import * as echarts from "echarts";

  export default {
    name: "ChinaEcharts",
    data() {
      return {
        chart: {},
        elevatorCountData: [],
        myGeo: new window.BMap.Geocoder(),
        chartData: [],
      };
    },
    mounted() {
      this.initChart();
      this.getElevatorCount();
    },
    methods: {
      initChart() {
        this.chart = echarts.init(document.getElementById("chart"));
        echarts.registerMap("china", require("@/assets/json/china.json"));
        window.onresize = this.chart.resize;
        this.chart.setOption(this.getChartOption());
      },
      getElevatorCount() {
        this.$api.getData("elevators/count").then(res => {
          this.elevatorCountData = res.data;
          for (let i = 0; i < this.elevatorCountData.length; i++) {
            this.geocodeSearch(i);
          }
        }).catch(error => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      // 根据城市名获取经纬度
      geocodeSearch(index) {
        let obj = this.elevatorCountData[index];
        this.myGeo.getPoint(obj.name, (point) => {
          if (point) {
            let item = {
              name: obj.name,
              value: [point.lng, point.lat, obj.value],
            };
            this.chartData.push(item);
            if (index === this.elevatorCountData.length - 1) {
              this.chart.setOption(this.getChartOption());
            }
          }
        }, "中国");
      },
      getChartOption() {
        return {
          backgroundColor: "#fff",
          title: {
            text: this.$t("home.liftsLocation"),
            subtext: "",
            sublink: "",
            left: "center",
            textStyle: {
              color: "#333",
            },
          },
          tooltip: {
            trigger: "item",
            formatter: function(params) {
              return params.name + " : " + params.value[2];
            },
          },
          legend: {
            orient: "vertical",
            y: "bottom",
            x: "right",
            data: ["数量"],
            textStyle: {
              color: "#fff",
            },
          },
          geo: {
            map: "china",
            zoom: 1.2,
            label: {
              emphasis: {
                show: false,
              },
            },
            roam: true,
            itemStyle: {
              normal: {
                areaColor: "#efefef",
                borderColor: "#AAA",
              },
              emphasis: {
                areaColor: "#e5e5e5",
              },
            },
          },
          series: [
            {
              name: "数量",
              type: "scatter",
              coordinateSystem: "geo",
              data: this.chartData,
              symbolSize: function(val) {
                return Math.ceil(val[2] / 50) < 4 ? 4 : Math.ceil(val[2] / 50);
              },
              label: {
                normal: {
                  formatter: "{b}",
                  position: "right",
                  show: false,
                },
                emphasis: {
                  show: true,
                },
              },
              itemStyle: {
                normal: {
                  color: "#0288D1",
                },
              },
            },
            {
              name: "Top 5",
              type: "effectScatter",
              coordinateSystem: "geo",
              data: this.chartData.sort(function(a, b) {
                return b.value - a.value;
              }).slice(0, 5),
              symbolSize: function(val) {
                return Math.ceil(val[2] / 50) + 1;
              },
              showEffectOn: "render",
              rippleEffect: {
                brushType: "stroke",
              },
              hoverAnimation: true,
              itemStyle: {
                normal: {
                  color: "#05C3F9",
                  shadowBlur: 10,
                  shadowColor: "#2d8cf0",
                },
              },
              zlevel: 1,
            },
          ],
        };
      },
    },
  };
</script>
